import {Button, Form, Modal, Input} from "antd";
import React, {useEffect, useState} from "react";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import KeyOutlined from "@ant-design/icons/lib/icons/KeyOutlined";
import {useDispatch, useSelector} from "react-redux";
import {requestSignUp, signIn, signInGoogle} from "../../redux/actions/auth";
import { GoogleLogin } from 'react-google-login';
import {solvergyLogo} from "../Logos/logos";
import "../../App.less";

const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};


export const SignInModal = ({signInModalIsOpened, setSignInModalIsOpened, setSignUpModalIsOpened}) => {

    const dispatch = useDispatch()

    const isAuth = useSelector(state => state.auth.isAuth)
    const isAuthInProcess = useSelector(state => state.auth.isAuthInProcess)
    const error = useSelector(state => state.auth.error)

    const initialForm = [{
        name: ["login"],
        value: ""
    }, {
        name: ["password"],
        value: ""
    }]

    const [fields, setFields] = useState(initialForm)

    useEffect(() => {
        if (isAuth) setSignInModalIsOpened(false)
    }, [isAuth])

    const onSignIn = () => {
        let authData = {}
        fields.forEach(({name, value}) => authData = {...authData, [name[0]]: value})
        dispatch(requestSignUp(authData.login))
        setFields(initialForm)
        dispatch(signIn(authData))
    }

    // const responseGoogle = (response) => {
    //
    //     //console.log(response);
    //
    //     if (response.profileObj !== undefined) {
    //         setFields(initialForm)
    //         dispatch(signInGoogle({login: response.profileObj.email,}))
    //     } else {
    //         console.log("cancelled")
    //     }
    // }

    return <Modal
        title={null}
        visible={signInModalIsOpened}
        destroyOnClose={true}
        onCancel={() => {
            setFields(initialForm)
            setSignInModalIsOpened(false)
        }}
        width={380}
        footer={null}
    >
        <Form {...layout}
              onFieldsChange={(changedFields, allFields) => {
                  setFields(allFields)
              }}
              name="control"
              onFinish={onSignIn}
        >
            <img
                src={solvergyLogo}
                className="headerLogo"
            />
            <p style={{fontWeight: 500, marginTop: 20, fontFamily: "Montserrat"}}>Sign in to your <b>Solvergy</b> account to manage your licenses.</p>

            <Form.Item name="login" rules={[{required: true, message: 'Enter login!'}]}>
                <Input placeholder="Login" prefix={<UserOutlined/>} style={{marginTop: 10, fontFamily: "Montserrat"}}/>
            </Form.Item>

            <Form.Item name="password" rules={[{required: true, message: 'Enter password!'}]}>
                <Input.Password placeholder="Password" prefix={<KeyOutlined/>} style={{marginTop: 10, fontFamily: "Montserrat"}}/>
            </Form.Item>

            {error && <>
                <p style={{fontWeight: 400, marginTop: 20, fontSize: 12, color: "red", fontFamily: "Montserrat"}}>You entered a non-existent login or an incorrect password.</p>
            </>}


            <Form.Item style={{paddingTop: 10, textAlign: "end", fontFamily: "Montserrat"}}>
                <Button type="default" onClick={() => {
                    setFields(initialForm)
                    setSignInModalIsOpened(false)
                }}>
                    Close
                </Button>

                <Button type="primary" htmlType="submit" style={{marginLeft: 10, fontFamily: "Montserrat"}} loading={isAuthInProcess}>
                    Sign in
                </Button>
            </Form.Item>

            {/*<hr style={{*/}
            {/*    width: "80%", border: 0,*/}
            {/*    height: 0,*/}
            {/*    borderTop: "1px solid rgba(0, 0, 0, 0.1)",*/}
            {/*    borderBottom: "1px solid rgba(255, 255, 255, 0.3)"*/}
            {/*}}/>*/}

            {/*<p style={{fontWeight: 500, marginTop: 20, textAlign: "center"}}>or sign in with:</p>*/}

            {/*<div style={{marginTop: 20, textAlign: "center"}}>*/}
            {/*    <GoogleLogin*/}
            {/*        clientId="386191605453-1btp3i21gb08828e31snlg08hieno984.apps.googleusercontent.com"*/}
            {/*        buttonText="Login"*/}
            {/*        render={renderProps => (*/}
            {/*            <Button type="primary" htmlType="default" onClick={renderProps.onClick} disabled={renderProps.disabled} style={{marginLeft: 10}} loading={isAuthInProcess}>*/}
            {/*                Google*/}
            {/*            </Button>*/}
            {/*        )}*/}
            {/*        onSuccess={responseGoogle}*/}
            {/*        onFailure={responseGoogle}*/}
            {/*        cookiePolicy={'single_host_origin'}*/}
            {/*    />*/}
            {/*</div>*/}


            <Button type="link" style={{fontWeight: 500, marginTop: 24, textAlign: "end", fontFamily: "Montserrat"}} onClick={() => {
                setSignInModalIsOpened(false)
                setSignUpModalIsOpened(true)
            }}>
                I don't have Solvergy account.
            </Button>
        </Form>

    </Modal>
}