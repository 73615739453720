import {createAxiosInstance} from "./axios-connection";

export const InfoAPI = {
    async getPricingPlans() {
        const instance = await createAxiosInstance();

        return instance.get(`systems/pricing_plans/`)
            .then(response => {
                return response.data;
            }).catch((e) => {
                return e
            })

    },

    async incrementSystemsWinDownloadsCount() {
        const instance = await createAxiosInstance()

        return instance.get(`statistics/increment_systems_win_downloads_count/`)
            .then(response => {
                return response.data;
            }).catch((e) => {
                return e
            })

    },

    async incrementSystemsMacDownloadsCount() {
        const instance = await createAxiosInstance()

        return instance.get(`statistics/increment_systems_mac_downloads_count/`)
            .then(response => {
                return response.data;
            }).catch((e) => {
                return e
            })

    },

    async getStats() {
        const instance = await createAxiosInstance()

        return instance.get(`statistics/full_stats/`)
            .then(response => {
                return response.data;
            }).catch((e) => {
                return e
            })

    },

    async getBlogPosts() {
        const instance = await createAxiosInstance()

        return instance.get(`posts/get_all_posts/`)
            .then(response => {
                return response.data;
            }).catch((e) => {
                return e
            })

    },

    async addNewPostToBlog(postData) {
        const instance = await createAxiosInstance(true)

        return instance.post(`posts/add_new_post/`, postData)
            .then(response => {
                return response.data;
            }).catch((e) => {
                return e
            })

    },
}
