import {
    FAILURE_LOGIN,
    FAILURE_SIGNUP,
    LOGOUT,
    REQUEST_LOGIN,
    REQUEST_SIGNUP, SET_AUTH_IN_PROCESS, SET_USER,
    SUCCESS_LOGIN,
    SUCCESS_SIGNUP
} from "../constants/auth";
import {AuthAPI} from "../../api/auth"

function requestLogin(login) { return { type: REQUEST_LOGIN, login } }
export function successLogin(user) { return { type: SUCCESS_LOGIN, user } }
function failureLogin(error) { return { type: FAILURE_LOGIN, error } }

export function requestSignUp(login) { return { type: REQUEST_SIGNUP, login } }
function successSignUp(user) { return { type: SUCCESS_SIGNUP, user } }
function failureSignUp(error) { return { type: FAILURE_SIGNUP, error } }

function setUser(data) { return { type: SET_USER, data } }

function setAuthInProcess(data) { return { type: SET_AUTH_IN_PROCESS, data } }

export function signIn({login, password}) {
    return dispatch => {
        dispatch(setAuthInProcess(true))
        //dispatch(requestLogin(login))

        AuthAPI.signIn(login, password)
            .then(
                user => {
                    dispatch(successLogin(user))
                    dispatch(setAuthInProcess(false))
                },
                error => {
                    dispatch(failureLogin(error))
                    dispatch(setAuthInProcess(false))
                }
            );
    };
}

export function signUp({login, password}) {
    return dispatch => {
        dispatch(setAuthInProcess(true))
        //dispatch(requestSignUp(login));

        AuthAPI.signUp(login, password)
            .then(
                user => {
                    dispatch(successSignUp(user))
                    dispatch(setAuthInProcess(false))
                },
                error => {
                    dispatch(failureSignUp(error))
                    dispatch(setAuthInProcess(false))
                }
            );
    };
}

export function signInGoogle({login}) {
    return dispatch => {
        dispatch(setAuthInProcess(true))


        AuthAPI.signInGoogle(login)
            .then(
                user => {
                    dispatch(successSignUp(user))
                    dispatch(setAuthInProcess(false))
                },
                error => {
                    dispatch(failureSignUp(error))
                    dispatch(setAuthInProcess(false))
                }
            );
    };
}

export function logout() {
    return dispatch => {
        AuthAPI.logout()
        dispatch(signout())
    }
}

export const setInitialUserLicense = () => dispatch => {
    return new Promise(async (res) => {
        const updatedUser = await AuthAPI.setInitialUserLicense()
        dispatch(setUser(updatedUser.data))
    });
}

export const getUserInfo = () => dispatch => {
    return new Promise(async (res) => {
        const user = await AuthAPI.getUserProfile()
        dispatch(setUser(user.data))
    });
}

export const setUserLicense = ({monthCount, planName}) => dispatch => {
    return new Promise(async (res) => {
        const updatedUser = await AuthAPI.setUserLicense({monthCount, planName})
        dispatch(setUser(updatedUser.data))
    });
}

export const signout = () => ({
    type: LOGOUT,
})

