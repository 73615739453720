import {
    SET_BLOG,
    SET_FULL_STATS,
    SET_PRICING_PLANS,
    SET_SYSTEMS_LATEST_RELEASE_LINKS,
    TOGGLE_BLOG_IS_LOADING, TOGGLE_PRICING_IS_LOADING
} from "../constants/info";

const initialState = {
    pricingPlans: null,
    systemsReleaseLinks: {
        mac: null,
        win: null
    },
    stats: null,
    posts: [],
    blogIsLoading: false,
    pricingIsLoading: false
}

const info = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRICING_PLANS:
            return {
                ...state,
                pricingPlans: action.data,
            }
        case SET_SYSTEMS_LATEST_RELEASE_LINKS:
            return {
                ...state,
                systemsReleaseLinks: action.data,
            }
        case SET_FULL_STATS:
            return {
                ...state,
                stats: action.data,
            }
        case SET_BLOG:
            return {
                ...state,
                posts: action.data,
            }
        case TOGGLE_BLOG_IS_LOADING:
            return {
                ...state,
                blogIsLoading: action.data,
            }
        case TOGGLE_PRICING_IS_LOADING:
            return {
                ...state,
                pricingIsLoading: action.data,
            }
        default:
            return state;
    }
}

export default info;
