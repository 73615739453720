import React from 'react';
import Fade from "react-reveal/Fade";
import {Grid, Button, Col, Menu, Row} from "antd";
import {NavLink} from "react-router-dom";
import YoutubeFilled from "@ant-design/icons/lib/icons/YoutubeFilled";
import {solvergyLogo} from "../Logos/logos";
const { useBreakpoint } = Grid
const {SubMenu} = Menu;

function Footer({pathname}) {

    const breakpoint = useBreakpoint()


    return (
        <div>
            <Row>
                <Col sm={24} xs={24} md={24} lg={24} xl={6} className="vh-center" style={{height: !breakpoint.xl || !breakpoint.lg ? 200 : 340}}>
                    <Fade left>
                        <div style={{textAlign: "start"}}>
                            <img
                                src={solvergyLogo}
                                className="headerLogo"
                            />
                            <span className="footerText" style={{marginTop: 10, display: "block"}}>© Copyright 2020 Solvergy.</span>
                            <span className="footerText"
                                  style={{marginTop: 10, display: "block"}}>All rights reserved.</span>

                            <span className="footerText" style={{display: "block", marginTop: 20, fontSize: 15}}>Contact: <ins>help@solvergy.org</ins></span>
                        </div>
                    </Fade>
                </Col>
                <Col sm={24} xs={24} md={24} lg={24} xl={12} className="vh-center" style={{height: !breakpoint.xl || !breakpoint.lg ? 200 : 340}}>
                    <Fade bottom>
                        <span className="footerTitle">Navigation</span>
                        <Menu
                            theme="light"
                            mode="horizontal"
                            defaultSelectedKeys={[pathname]}
                            selectedKeys={[pathname]}
                            style={{
                                backgroundColor: "#fff",
                                border: "0px solid black",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width:  !breakpoint.xl || !breakpoint.lg ? 260 : 600
                            }}
                        >
                            <Menu.Item key="/">
                                <NavLink to="/" className="nav-link">
                                    Home
                                </NavLink>
                            </Menu.Item>

                            <Menu.Item key="/about">
                                <NavLink to="/about" className="nav-link">
                                    About
                                </NavLink>
                            </Menu.Item>

                            <SubMenu key="3" title="Products" className="nav-link">

                                <Menu.Item key="/systems">

                                    <NavLink to="/systems" className="nav-link">
                                        Solvergy: Systems
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="/buildings">
                                    <NavLink to="/buildings" className="nav-link">
                                        Solvergy: Buildings
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="/sources" disabled={true}>
                                    <NavLink to="/sources" className="nav-link">
                                        Solvergy: Sources
                                    </NavLink>
                                </Menu.Item>
                            </SubMenu>

                            <Menu.Item key="/downloads">
                                <NavLink to="/downloads" className="nav-link">
                                    Downloads
                                </NavLink>
                            </Menu.Item>

                            <Menu.Item key="/blog">
                                <NavLink to="/blog" className="nav-link">
                                    Blog
                                </NavLink>
                            </Menu.Item>

                            <SubMenu key="4" title="Documentation" className="nav-link">

                                <Menu.Item key="/documentation-systems">

                                    <NavLink to="/documentation-systems" className="nav-link">
                                        Solvergy: Systems
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="/documentation-buildings">
                                    <NavLink to="/documentation-buildings" className="nav-link">
                                        Solvergy: Buildings
                                    </NavLink>
                                </Menu.Item>
                            </SubMenu>

                            <Menu.Item key="/pricing">
                                <NavLink to="/pricing" className="nav-link">
                                    Pricing
                                </NavLink>
                            </Menu.Item>
                        </Menu>
                    </Fade>
                </Col>
                <Col sm={24} xs={24} md={24} lg={24} xl={6} className="vh-center" style={{height: !breakpoint.xl || !breakpoint.lg ? 200 : 340}}>
                    <Fade right>
                        <div style={{textAlign: "center"}}>
                            <span className="footerTitle" style={{display: "block"}}>Follow us</span>
                            <Button type="link" shape="circle" icon={<YoutubeFilled style={{fontSize: 40}}/>}
                                    size={"large"} onClick={(e) => {
                                e.preventDefault();
                                window.open("https://www.youtube.com/channel/UCmT1Wowo8ygcPOuF6qPeFXg", "_blank")
                            }}/>
                        </div>
                    </Fade>
                </Col>
            </Row>
        </div>
    );
}

export default Footer;