import {Button, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Select} from 'antd';
import {getUserInfo} from "../../redux/actions/auth";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import StopOutlined from "@ant-design/icons/lib/icons/StopOutlined";
import {buildingsLogo, sourcesLogo, systemsLogo} from "../Logos/logos";

const {Option} = Select;

export const LicenseModal = ({licenseModalIsOpened, setLicenseModalIsOpened}) => {

    const dispatch = useDispatch()

    const isAuth = useSelector(state => state.auth.isAuth)
    const user = useSelector(state => state.auth.user)

    const [selectedProduct, setSelectedProduct] = useState(null)

    useEffect(() => {
        if (licenseModalIsOpened) dispatch(getUserInfo())
    }, [licenseModalIsOpened])

    useEffect(() => {
        if (isAuth) setLicenseModalIsOpened(false)
    }, [isAuth])

    return <Modal
        title={"Licenses"}
        destroyOnClose={true}
        visible={licenseModalIsOpened}
        onCancel={() => {
            setSelectedProduct(null)
            setLicenseModalIsOpened(false)
        }}
        width={600}
        footer={[
            <Button key="back" onClick={() => {
                setSelectedProduct(null)
                setLicenseModalIsOpened(false)
            }}>
                Close
            </Button>,
        ]}
    >

        <Select value={selectedProduct} placeholder={"Select product..."} style={{width: 300}}
                onChange={(value) => setSelectedProduct(value)} size={"large"}>
            <Option value="systems" style={{padding: 10}}>
                <img
                    src={systemsLogo}
                    className="licenseLogo"
                />
                <span style={{fontSize: 15, fontWeight: 700, marginLeft: 8, position: "relative", top: 1}}>Solvergy: Systems</span>
            </Option>
            <Option value="buildings" style={{padding: 10}}>
                <img
                    src={buildingsLogo}
                    className="licenseLogo"
                />
                <span style={{fontSize: 15, fontWeight: 700, marginLeft: 8, position: "relative", top: 1}}>Solvergy: Buildings</span>
            </Option>
            <Option value="sources" disabled style={{padding: 10}}>
                <img
                    src={sourcesLogo}
                    className="licenseLogo"
                />
                <span style={{fontSize: 15, fontWeight: 700, marginLeft: 8, position: "relative", top: 1}}>Solvergy: Sources</span>
            </Option>
        </Select>

        <br/><br/>

        {selectedProduct === "buildings" && <div style={{padding: 20, paddingBottom: 6, border: "1px solid #eee"}}>
            <p className="licenseDescription">
                <b>Solvergy: Buildings</b> mobile application is free.
            </p>
            <p className="licenseDescription">
                Download it from <a target="_blank" href={"https://apps.apple.com/us/app/id1518494813"}>App
                Store</a> or <a target="_blank"
                                href={"https://play.google.com/store/apps/details?id=com.solvergy.buildings"}>Google
                Play</a>.
            </p>
        </div>}

        {selectedProduct === "systems" && <div style={{padding: 20, paddingBottom: 6, border: "1px solid #eee"}}>
            {user.systemsLicense && <>
                <p className="licenseDescription">
                    Your current pricing plan: <b>{user.systemsLicense.pricingPlan.planName}</b>.
                </p>

                {user.systemsLicense.pricingPlan.planName !== "Free" && <>
                    {(new Date(user.systemsLicense.until)) < (new Date()) ?
                        <p style={{fontSize: 22, color: "#b41416", marginTop: 20}}>
                            <StopOutlined/> License is expired.
                        </p>
                        :
                        <p style={{fontSize: 22, color: "#33b424", marginTop: 20}}>
                            <CheckCircleOutlined/> License is active.
                        </p>
                    }

                </>}


                {user.systemsLicense.pricingPlan.planName !== "Free" && <>
                    <p className="licenseDescription">
                        From: <b>{(new Date(user.systemsLicense.from)).toLocaleDateString(undefined, {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    })}</b>, until: <b>{(new Date(user.systemsLicense.until)).toLocaleDateString(undefined, {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    })}</b>.
                    </p>
                    <p className="licenseDescription">
                        Monthly price: <b>$ {user.systemsLicense.pricingPlan.monthlyPrice}</b>.
                    </p>
                    <p className="licenseDescription">
                        Annual price: <b>$ {user.systemsLicense.pricingPlan.annualPrice}</b>.
                    </p>
                </>}
                <br/>

                <p className="licenseDescription">
                    Pricing plan features:
                </p>
                <ul style={{listStyle: "none"}}>
                    {user.systemsLicense.pricingPlan.features.map(({name, isAvailable}, i) => {
                        return <li key={i} style={{color: isAvailable ? "#43913b" : "#8b8b8b", fontSize: 13}}>{isAvailable ?
                            <CheckOutlined/> : <CloseOutlined/>} {name}</li>
                    })}
                </ul>


            </>}
            <br/>
            <p className="licenseDescription">
                <b>Solvergy: Systems</b> software <a href="/pricing">pricing plans</a>.
            </p>
        </div>}


    </Modal>
}