import axios from "axios";

export const GitHubAPI = {
    async getLatestRelease() {
        const instance = axios.create({
                // headers: {
                //     'Authorization': `token ${process.env.REACT_APP_GH_TOKEN}`,
                // },
                baseURL: "https://api.github.com/",
            }
        )

        return instance.get(`repos/dmitriy-qua/solvergy-systems/releases/latest`)
            .then(response => {
                return response.data;
            }).catch((e) => {
                return e
            })

    },
}