import React from 'react';
import loading from "../../assets/images/loading.gif"

function Loading() {

    return (
        <div style={{textAlign: "center", marginTop: 90}}>

            <img src={loading} width={70}/>

        </div>
    );
}

export default Loading;