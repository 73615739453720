import {
    SET_BLOG,
    SET_FULL_STATS,
    SET_PRICING_PLANS,
    SET_SYSTEMS_LATEST_RELEASE_LINKS,
    TOGGLE_BLOG_IS_LOADING, TOGGLE_PRICING_IS_LOADING
} from "../constants/info";
import {InfoAPI} from "../../api/info";
import {GitHubAPI} from "../../api/github";

export const setPricingPlans = (data) => ({
    type: SET_PRICING_PLANS,
    data
})

export const setLatestSystemsReleaseLinks = (data) => ({
    type: SET_SYSTEMS_LATEST_RELEASE_LINKS,
    data
})

export const setFullStats = (data) => ({
    type: SET_FULL_STATS,
    data
})

export const setBlogPosts = (data) => ({
    type: SET_BLOG,
    data
})

export const toggleBlogIsLoading = (data) => ({
    type: TOGGLE_BLOG_IS_LOADING,
    data
})

export const togglePricingIsLoading = (data) => ({
    type: TOGGLE_PRICING_IS_LOADING,
    data
})

export const getPricingPlans = () => dispatch => {
    return new Promise(async (res) => {
        dispatch(togglePricingIsLoading(true))
        const pricingPlans = await InfoAPI.getPricingPlans()
        dispatch(setPricingPlans(pricingPlans.data))
        dispatch(togglePricingIsLoading(false))
    });
}

export const getBlogPosts = () => dispatch => {
    return new Promise(async (res) => {
        dispatch(toggleBlogIsLoading(true))
        const blogPosts = await InfoAPI.getBlogPosts()
        const sortedPosts = sortPostsByDate(blogPosts.data)
        dispatch(setBlogPosts(sortedPosts))
        dispatch(toggleBlogIsLoading(false))
    });
}

export const addNewPostToBlog = (postData) => dispatch => {
    return new Promise(async (res) => {
        dispatch(toggleBlogIsLoading(true))
        const blogPosts = await InfoAPI.addNewPostToBlog(postData)
        const sortedPosts = sortPostsByDate(blogPosts.data)
        dispatch(setBlogPosts(sortedPosts))
        dispatch(toggleBlogIsLoading(false))
    });
}

export const incrementSystemsWinDownloadsCount = () => dispatch => {
    return new Promise(async (res) => {
        await InfoAPI.incrementSystemsWinDownloadsCount()
    });
}

export const incrementSystemsMacDownloadsCount = () => dispatch => {
    return new Promise(async (res) => {
        await InfoAPI.incrementSystemsMacDownloadsCount()
    });
}

export const getStats = () => dispatch => {
    return new Promise(async (res) => {
        const stats = await InfoAPI.getStats()
        dispatch(setFullStats(stats.data))

    });
}

export const getLatestSystemsReleaseLinks = () => dispatch => {
    return new Promise(async (res) => {
        const latestReleaseInfo = await GitHubAPI.getLatestRelease()
        const {name, tag_name} = latestReleaseInfo

        const systemsReleaseLinks = {
            mac: `https://github.com/dmitriy-qua/solvergy-systems/releases/download/${tag_name}/Solvergy-Systems-${name}.dmg`,
            win: `https://github.com/dmitriy-qua/solvergy-systems/releases/download/${tag_name}/Solvergy-Systems-Setup-${name}.exe`
        }

        dispatch(setLatestSystemsReleaseLinks(systemsReleaseLinks))
    });
}

const sortPostsByDate = (blogPosts) => {
    const sortedPosts = [...blogPosts]
    sortedPosts.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date);
    })

    return sortedPosts
}


