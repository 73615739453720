import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import auth from "./reducers/auth";
import info from "./reducers/info";

let reducers = combineReducers({
    auth,
    info
})

const store = createStore(reducers, applyMiddleware(thunkMiddleware));

//window.store = store

export default store;
