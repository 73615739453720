import React, {useEffect, useState, Suspense, lazy} from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import "./App.less";

import Navbar from "./components/Navbar/Navbar";
//import Home from "./components/Home/Home";
import {Layout} from "antd";
import FooterMain from "./components/Footer/Footer";
import useScript from "./hooks/useScript";
import {useDispatch, useSelector} from "react-redux";
import {setInitialUserLicense, successLogin} from "./redux/actions/auth";
import {getLatestSystemsReleaseLinks} from "./redux/actions/info";
import Loading from "./components/Loading/Loading";

const Home = lazy(() => import("./components/Home/Home"))
const About = lazy(() => import("./components/About/About"))
const Buildings = lazy(() => import("./components/Buildings/Buildings"))
const Systems = lazy(() => import("./components/Systems/Systems"))
const BuildingsDocumentation = lazy(() => import("./components/Documentation/Buildings"))
const SystemsDocumentation = lazy(() => import("./components/Documentation/Systems"))
const Pricing = lazy(() => import("./components/Pricing/Pricing"))
const Downloads = lazy(() => import("./components/Downloads/Downloads"))
const Blog = lazy(() => import("./components/Blog/Blog"))
const Stats = lazy(() => import("./components/Stats/Stats"))
const NotFound = lazy(() => import("./components/NotFound/NotFound"))

const {Header, Footer, Content} = Layout;

export const AppContext = React.createContext();

function App() {

    const dispatch = useDispatch()

    useScript("https://secure.wayforpay.com/server/pay-widget.js")

    const user = useSelector(state => state.auth.user)

    const {pathname} = useLocation()

    useEffect(() => {
        dispatch(getLatestSystemsReleaseLinks())
    }, [])

    useEffect(() => {
        if (pathname) window.scroll(0, 0)
    }, [pathname]);

    const [scrollTop, setScrollTop] = useState(null)

    useEffect(() => {
        const user = localStorage.getItem('user')
        if (user) dispatch(successLogin(JSON.parse(user).data.user))
    }, [])

    useEffect(() => {
        if (user && user.systemsLicense === undefined) dispatch(setInitialUserLicense())
    }, [user])

    useEffect(() => {
        window.addEventListener(
            "scroll",
            function (event) {
                setScrollTop(this.scrollY);
            },
            false
        );
    }, [])

    const [signInModalIsOpened, setSignInModalIsOpened] = useState(false)
    const [signUpModalIsOpened, setSignUpModalIsOpened] = useState(false)
    const [licenseModalIsOpened, setLicenseModalIsOpened] = useState(false)

    return (
        <div className="App">


            <Layout className="layout" style={{backgroundColor: "#fff"}}>
                <Header
                    style={{
                        position: "fixed",
                        zIndex: 1,
                        width: "100%",
                        backgroundColor: "#fff",
                        boxShadow:
                            scrollTop > 2
                                ? "0 0 10px rgba(0,0,0,0.15)"
                                : "0 0 10px rgba(0,0,0,0.0)",
                        transition: "box-shadow 0.2s ease-in-out",
                    }}
                >
                    <Navbar pathname={pathname}
                            signInModalIsOpened={signInModalIsOpened}
                            setSignInModalIsOpened={setSignInModalIsOpened}
                            signUpModalIsOpened={signUpModalIsOpened}
                            setSignUpModalIsOpened={setSignUpModalIsOpened}
                            licenseModalIsOpened={licenseModalIsOpened}
                            setLicenseModalIsOpened={setLicenseModalIsOpened}
                    />
                </Header>
                <Content
                    style={{marginTop: 64, backgroundColor: "#fff"}}
                >
                    <AppContext.Provider value={{setSignUpModalIsOpened, setSignInModalIsOpened}}>
                        <Suspense fallback={<Loading/>}>
                            <Switch>
                                <Route exact path="/" component={Home}/>
                                <Route path="/about" component={About}/>
                                <Route path="/buildings" component={Buildings}/>
                                <Route path="/systems" component={Systems}/>
                                <Route path="/documentation-buildings" component={BuildingsDocumentation}/>
                                <Route path="/documentation-systems" component={SystemsDocumentation}/>
                                <Route path="/pricing" component={Pricing}/>
                                <Route path="/downloads" component={Downloads}/>
                                <Route path="/blog" component={Blog}/>
                                <Route path="/stats" component={Stats}/>
                                <Route component={NotFound}/>
                            </Switch>
                        </Suspense>
                    </AppContext.Provider>

                </Content>
                <Footer style={{backgroundColor: "#fff"}}>
                    <FooterMain pathname={pathname}/>
                </Footer>
            </Layout>
        </div>
    );
}

export default App;
