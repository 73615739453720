import React from "react";
import {NavLink} from "react-router-dom";
import {Menu, Col, Row, Button, Dropdown, Grid} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {SignInModal} from "./SignInModal";
import {SignUpModal} from "./SignUpModal";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import {logout} from "../../redux/actions/auth";
import {LicenseModal} from "./LicenseModal";
import {solvergyLogo} from "../Logos/logos";
import "../../App.less";

const { useBreakpoint } = Grid

const {SubMenu} = Menu;

function Navbar({pathname, signInModalIsOpened, setSignInModalIsOpened, signUpModalIsOpened, setSignUpModalIsOpened, licenseModalIsOpened, setLicenseModalIsOpened}) {

    const breakpoint = useBreakpoint()

    const isAuth = useSelector(state => state.auth.isAuth)
    const user = useSelector(state => state.auth.user)

    const dispatch = useDispatch()

    const profileMenu = (
        <Menu style={{width: 130}}>
            <Menu.Item key="0" style={{padding: "10px 40px 10px 10px", fontWeight: 700, fontFamily: "Montserrat"}} onClick={() => setLicenseModalIsOpened(true)}>
                <a rel="noopener noreferrer">
                    Licenses
                </a>
            </Menu.Item>
            <Menu.Item key="1" style={{padding: "10px 40px 10px 10px", fontWeight: 500, fontFamily: "Montserrat"}} onClick={() => dispatch(logout())}>
                <a rel="noopener noreferrer">
                    Sign out
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div>
            <Row>
                <Col span={4}>
                    <div
                        style={!breakpoint.lg ? {marginLeft: -40, float: "left"} : {
                            margin: "0px 0px 0px 0px",
                            float: "left",
                        }}
                    >
                        <img
                            src={solvergyLogo}
                            className="headerLogo"
                        />
                    </div>
                </Col>
                <Col span={14}>
                    <Menu
                        theme="light"
                        mode="horizontal"

                        defaultSelectedKeys={[pathname]}
                        style={{
                            // width: "100%",
                            backgroundColor: "#fff",
                            border: "0px solid black",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: 61
                        }}
                        selectedKeys={[pathname]}
                    >
                        <Menu.Item key="/">
                            <NavLink to="/" className="nav-link">
                                Home
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/about">
                            <NavLink to="/about" className="nav-link">
                                About
                            </NavLink>
                        </Menu.Item>

                        <SubMenu key="3" title="Products" className="nav-link">

                            <Menu.Item key="/systems">

                                <NavLink to="/systems" className="nav-link">
                                    Solvergy: Systems
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/buildings">
                                <NavLink to="/buildings" className="nav-link">
                                    Solvergy: Buildings
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/sources" disabled={true}>
                                <NavLink to="/sources" className="nav-link">
                                    Solvergy: Sources
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item key="/downloads">
                            <NavLink to="/downloads" className="nav-link">
                                Downloads
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/blog">
                            <NavLink to="/blog" className="nav-link">
                                Blog
                            </NavLink>
                        </Menu.Item>

                        <SubMenu key="4" title="Documentation" className="nav-link">

                            <Menu.Item key="/documentation-systems">

                                <NavLink to="/documentation-systems" className="nav-link">
                                    Solvergy: Systems
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/documentation-buildings">
                                <NavLink to="/documentation-buildings" className="nav-link">
                                    Solvergy: Buildings
                                </NavLink>
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item key="/pricing">
                            <NavLink to="/pricing" className="nav-link">
                                Pricing
                            </NavLink>
                        </Menu.Item>
                    </Menu>
                </Col>
                <Col span={6} style={{textAlign: "end"}}>
                    {!isAuth && !user ? <>
                            <Button
                                type="primary"
                                style={{marginRight: breakpoint.lg ? 10 : 0}}
                                className="buttonText"
                                onClick={() => setSignInModalIsOpened(true)}
                            >
                                Sign in
                            </Button>
                            {breakpoint.lg &&
                            <Button
                                type="dashed"
                                className="buttonText"
                                onClick={() => setSignUpModalIsOpened(true)}>
                                Sign up
                            </Button>}
                        </>
                        :
                        <Dropdown overlay={profileMenu} trigger={['click']}>
                            <Button className="buttonText" style={{fontWeight: 700}} icon={<UserOutlined style={{fontSize: 16}}/>} type={"dashed"} size={"middle"}>
                                {user.login}
                            </Button>
                        </Dropdown>
                    }

                </Col>
            </Row>

            <SignInModal signInModalIsOpened={signInModalIsOpened}
                         setSignInModalIsOpened={setSignInModalIsOpened}
                         setSignUpModalIsOpened={setSignUpModalIsOpened}
            />

            <SignUpModal signUpModalIsOpened={signUpModalIsOpened} setSignUpModalIsOpened={setSignUpModalIsOpened}/>

            <LicenseModal licenseModalIsOpened={licenseModalIsOpened} setLicenseModalIsOpened={setLicenseModalIsOpened}/>

        </div>
    );
}

export default Navbar;
