import {
    FAILURE_LOGIN,
    FAILURE_SIGNUP,
    LOGOUT,
    REQUEST_LOGIN,
    REQUEST_SIGNUP, SET_AUTH_IN_PROCESS, SET_USER,
    SUCCESS_LOGIN,
    SUCCESS_SIGNUP,
} from "../constants/auth";

const initialState = {
    isAuth: false,
    error: false,
    user: null,
    isAuthInProcess: false
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_LOGIN:
            return {
                ...state,
                isAuth: false,
                user: null, //action.login,
                error: false
            }
        case SUCCESS_LOGIN:
            return {
                ...state,
                isAuth: true,
                user: action.user,
                error: false
            }
        case FAILURE_LOGIN:
            return {
                ...state,
                isAuth: false,
                user: null,
                error: true
            };
        case REQUEST_SIGNUP:
            return {
                ...state,
                isAuth: false,
                user: null, //action.login,
                error: false
            }
        case SUCCESS_SIGNUP:
            return {
                ...state,
                isAuth: true,
                user: action.user,
                error: false
            }
        case FAILURE_SIGNUP:
            return {
                ...state,
                isAuth: false,
                user: null,
                error: true
            };
        case LOGOUT:
            return {
                ...state,
                isAuth: false,
                user: null,
                error: false
            }
        case SET_AUTH_IN_PROCESS:
            return {
                ...state,
                isAuthInProcess: action.data,
            }
        case SET_USER:
            return {
                ...state,
                user: action.data,
            }
        default:
            return state;
    }
}

export default auth;
